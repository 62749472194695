// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, H2, Div } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)`
    background: linear-gradient(180deg, rgba(13, 12, 8, 0) 0%, #0D0C08 38.37%);
`;

export const Title = styled(H2)((props) => css`
    color: ${props.theme.bc1};
    margin-bottom: 3.6rem;

    ${breakup.medium`
        padding-left: 4.2rem;
    `}
`);


export const List = styled(Div)``;