// Imports
// ------------
import React from 'react';
import Seo from '@parts/Seo';
import SmoothScroll from '@parts/SmoothScroll';
import Hero from '@parts/Hero';
import Overview from '@parts/Experiences/Overview';
import ExperiencesList from '@parts/Experiences/List';
import ExpContactModal from '@parts/Experiences/Modal';
import Faqs from '@parts/Experiences/Faqs';
import Footer from '@parts/Footer';
import { graphql } from 'gatsby';
import { observer } from 'mobx-react-lite';

// Page
// ------------
const ExperiencesPage = ({ data }) => {
    return (
        <>
            <Seo meta={data.page.seoMeta} />
            <ExpContactModal formSuccessMessage={data.global.formSuccessMessage} formErrorMessage={data.global.formErrorMessage} />

            <SmoothScroll id="scrollContainer">
                <Hero title={data.page.title} sub="Experience Days" goLarge />

                <Overview
                    content={data.page.overviewNode.childMarkdownRemark.html}
                    images={data.page.overviewImages}
                />

                <ExperiencesList data={data.page.experienceList} />

                <Faqs intro={data.page.faqIntroNode.childMarkdownRemark.html} data={data.page.faqList} />
                
                <Footer />
            </SmoothScroll>
        </>
    );
}

export default observer(ExperiencesPage);

// GraphQL
// ------
export const query = graphql`
    query ExperiencesQuery {
        global: datoCmsGlobalSetting {
            formSuccessMessage
            formErrorMessage
        }

		page: datoCmsExperiencePage {
            title

            overviewNode {
                childMarkdownRemark {
                    html
                }
            }
            overviewImages {
                alt
                data: gatsbyImageData(
                    width: 600
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                )
            }

            experienceList {
                title
                thumbnailImage {
                    alt
                    data: gatsbyImageData(
                        width: 600
                        placeholder: BLURRED
                        layout: FULL_WIDTH
                    )
                }
                
                descriptionNode {
                    childMarkdownRemark { html }
                }
                
                datesAvailable
                listOfDates {
                    dateTime
                }
                
                price
                
                testimonials {
                    quote
                    author
                    rating
                }
            }

            faqIntroNode {
                childMarkdownRemark {
                    html
                }
            }
            
            faqList {
                question
                answerNode {
                    childMarkdownRemark {
                        html
                    }
                }
            }

            seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
		}
	}
`;