// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, Span } from '@tackl';
import Topo from '@images/ps-topo-bg.png';

// Exports
// ------------
export const Jacket = styled(Section)`
    overflow-x: hidden;
`;

export const Content = styled.div((props) => css`
    margin-bottom: 12rem;

    ${breakup.large` margin-bottom: 0; `}
`);

export const Label = styled(Span)((props) => css`
    display: block;
    margin-bottom: 1.2rem;

    font-family: ${props.theme.heading};
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;

    color: ${props.theme.bc2};
`);

export const ImageList = styled.div`
    position: relative;
    height: 80vh;

    ${breakup.large` ${props => props.theme.vh}; `}
`;

export const Image = styled.div((props) => css`
    position: absolute;
    will-change: transform;
    transform: translate(80%, 0%);

    width: 100%;
    height: 100%;

    ${props.imageOne && css`
        z-index: 1;
        top: 15vh;
        width: 65vw;

        ${breakup.large` width: 30vw; `}
    `}
    
    ${props.imageTwo && css`
        z-index: 0;
        top: 10vh;
        right: 0;
        width: 75vw;

        ${breakup.large` width: 25vw; `}
    `}
    
    ${props.imageThree && css`
        z-index: 2;
        top: 10vh;
        left: 0;
        width: 50vw;

        ${breakup.large` width: 24vw; `}
    `}
    
    ${props.imageFour && css`
        z-index: 3;
        bottom: 0;
        right: 0;
        width: 80vw;

        ${breakup.large` width: 30vw; `}
    `}
`);


export const Topographic = styled.div((p) => css`
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: -1;

    display: block;
    width: 0%;
    height: 100%;
    overflow: hidden;
    background: url(${Topo}) repeat;
`);