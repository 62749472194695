// Imports
// ------------
import React, { useState } from 'react';
import Parser from '@parts/Parser';
import Icon from '@icons';
import Button from '@parts/Button';
import moment from 'moment';
import { experienceModal } from '@states';
import { Row, Column } from '@waffl';
import { GatsbyImage } from 'gatsby-plugin-image';
import { observer } from 'mobx-react-lite';
import { action } from 'mobx';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------------
import {
    Jacket,
    Content,
    Title,
    Description,
    ImageJacket,
    Review,
    ReviewDetail,
    Rating,
    ReviewContent,
    ReviewAuthor,
    Selector,
    Selected,
    SelectorTrigger,
    SelectorList,
    Nodates,
    Option,
    End,
    Price,
    ButtonJacket
} from './styles';

// Component
// ------------
const ExperienceCard = ({ data }) => {
    // NOTE • Breakpoints
	const bp = useBreakpoint();

    // NOTE • States
    const [selected, setSelected] = useState(false);
    const [title] = useState(data.title);
    const [thedate, setDate] = useState("");
    const [price] = useState(data.price);


    // NOTE • Functions
    const handleSelect = action(() => {
        setSelected(!selected)
    });

    const handleOption = action((e) => {
        // Set global states to use in other components (the modal)
        setDate(e.target.textContent);

        experienceModal.selectedExpDate = e.target.textContent;
        experienceModal.selectedExpTitle = title;
        experienceModal.selectedExpPrice = price;

        setSelected(false);
    });

    const handleClear = action(() => {
        setSelected(false);
        
        // Clear global states
        experienceModal.selectedExpDate = "";
        experienceModal.selectedExpTitle = "";
        experienceModal.selectedExpPrice = "";

        setDate("");
    });

    const handleModal = action(() => {
        // If one experience is selected already, re-init the selected to stop the bug
        experienceModal.selectedExpDate = thedate;
        experienceModal.selectedExpTitle = title;
        experienceModal.selectedExpPrice = price;

        experienceModal.isActive = true;
    });

    return (
        <Jacket>
            <Row isExpanded isCollapsed isEqual isCenter>
                <Column small={12} large={5}>
                    <ImageJacket>
                        <GatsbyImage image={data.thumbnailImage.data} alt={data.thumbnailImage.alt} />

                        {bp.medium && (
                            <Review>
                                <Icon type="quote" />
                                <ReviewDetail>
                                    <Rating>
                                        {[...Array(Number(data.testimonials[0].rating))].map((e, i) => {
                                            return <Icon key={i} type="star" />
                                        })}
                                    </Rating>
                                    <ReviewContent>{data.testimonials[0].quote}</ReviewContent>
                                    <ReviewAuthor>- {data.testimonials[0].author}</ReviewAuthor>
                                </ReviewDetail>
                            </Review>
                        )}
                    </ImageJacket>
                </Column>
                <Column small={12} large={7}>
                    {!bp.medium && (
                        <Review>
                            <Icon type="quote" />
                            <ReviewDetail>
                                <Rating>
                                    {[...Array(Number(data.testimonials[0].rating))].map((e, i) => {
                                        return <Icon key={i} type="star" />
                                    })}
                                </Rating>
                                <ReviewContent>{data.testimonials[0].quote}</ReviewContent>
                                <ReviewAuthor>- {data.testimonials[0].author}</ReviewAuthor>
                            </ReviewDetail>
                        </Review>
                    )}
                    <Content>
                        <Row isExpanded isCenter>
                            <Column small={12} medium={10} pushMedium={1}>
                                <Title>{data.title}</Title>
                                <Description>
                                    <Parser textSmall content={data.descriptionNode.childMarkdownRemark.html} />
                                </Description>

                                <Selector>
                                    {data.datesAvailable ? (
                                        <>
                                            <Selected>
                                                <span>{thedate ? <><em>Date:</em> {thedate}</> : `Select available date`}</span>
                                                <span role="button" onClick={thedate ? handleClear : null} className="ch">{thedate ? `Clear` : `Required`}</span>
                                            </Selected>
                                            <SelectorTrigger onClick={handleSelect} isActive={selected} className="ch" />
                                            <SelectorList isActive={selected}>
                                                {data.listOfDates.map(({dateTime}, i) => {
                                                    let newDate = moment(dateTime).format('Do MMM YYYY');
                                                    let newTime = moment(dateTime).format('LT');

                                                    return (
                                                        <Option key={i} isActive={experienceModal.selectedExpDate === newDate} onClick={(e) => handleOption(e)}>
                                                            {newDate} at {newTime}
                                                        </Option>
                                                    )
                                                })}
                                            </SelectorList>
                                        </>
                                    ) : (
                                        <Nodates>No dates available yet</Nodates>
                                    )}
                                </Selector>

                                <End>
                                    <Price><span>Price:</span> &pound;{data.price}</Price>

                                    <ButtonJacket>
                                        {data.datesAvailable ? (
                                            <Button disabled={!thedate} isBtn isNext label="Book Now" onClick={handleModal} />
                                        ) : (
                                            <Button isBtn isNext label="Taking early bookings" onClick={handleModal} />
                                        )}
                                    </ButtonJacket>
                                </End>
                            </Column>
                        </Row>
                    </Content>
                </Column>
            </Row>
        </Jacket>
    );
}

export default observer(ExperienceCard);