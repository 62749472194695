// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, Div, Em, P } from '@tackl';
import { h4Styles } from '@tackl-type';

// Exports
// ------------
export const Jacket = styled(Section)((props) => css`
    transition: all 1.2s ${props.theme.bezzy};
    position: fixed;
    top: 0; right: 0;
    z-index: 120;

    display: block;
    width: 100%;
    ${props.theme.vh}
    opacity: 1;
    overflow: hidden;

    background: ${props.theme.black};

    transform: translateX(${props.isActive ? `0%` : `100%`});

    ${breakup.medium`
        &:before {
            content: '';
            position: fixed;
            z-index: 2;
            top: 0; left: 0; bottom: 0;
            width: 2.4rem;
            background: ${props.theme.bc3};

            transition: all .6s ease-in-out;
            transition-delay: 1.2s;
            transform: translateX(${props.isActive ? `0%` : `-100%`});
        }
    `}
`);

export const Content = styled(Div)`
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
`;

export const FormJacket = styled(Div)``;

export const Details = styled(Div)`
    margin-bottom: 3.6rem;
    
    ${breakup.medium` margin-bottom: 6rem; `}
`;

export const Label = styled(Em)`
    margin-top: 6rem;

    ${breakup.medium` margin-top: 0; `}
`;

export const Item = styled(P)((props) => css`
    margin-top: 1.2rem;

    ${props.big && css`
        ${h4Styles}
        color: ${props.theme.bc1};
    `}

    ${props.price && css`
        font-weight: 600;
        margin-top: 0;
    `}
`);