// Imports
// ------------
import styled from 'styled-components';
import { breakup, Section, Div } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)``;

export const HeroJacket = styled.div`
    position: relative;
    top: -2rem;

    ${breakup.large` top: -6rem; `}
`;

export const ButtonJacket = styled.div`
    justify-content: flex-start;
`;

export const Content = styled(Div)``;

export const Questions = styled(Div)`
    padding-top: 6rem;

    ${breakup.medium` padding-top: 0; `}
`;