// Imports
// ------------
import React, { useState } from 'react';
import Icon from '@icons';
import Parser from '@parts/Parser';

// Styles
// ------------
import { Jacket, Title, Content } from './styles';

// Component
// ------------
const FaqQuestion = ({ question, content }) => {
    // States
    const [isActive, setIsActive] = useState(false);

    // Functions
    const handleFaq = () => {
        setIsActive(!isActive);
    }

    return (
        <Jacket isActive={isActive}>
            <Title onClick={handleFaq} isActive={isActive} className="ch">{question} <span><Icon type="down" /></span></Title>

            <Content isActive={isActive}>
                <Parser content={content} />
            </Content>
        </Jacket>
    );
}

export default FaqQuestion;