// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, H1, H2} from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)`
    position: relative;
`;

export const Back = styled.button((props) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    width: auto;
    min-width: max-content;
    max-width: max-content;
    background: none;
    border: none;
    box-shadow: none;

    margin-bottom: 2.4rem;

    ${breakup.medium`
        margin-left: 4.2rem;
    `}

    svg {
        transition: all .6s ${props.theme.bezzy};
        fill: ${props.theme.bc2};
        margin-right: 1.2rem;
    }

    span {
        transition: all .6s ${props.theme.bezzy};
        font-family: ${props.theme.heading};
        font-size: 1.6rem;
        text-transform: uppercase;
        color: ${props.theme.bc2};
    }

    &:hover {
        svg { fill: ${props.theme.white}; }
        span { color: ${props.theme.white}; }
    }
`);

export const TitleJacket = styled.div`
    position: absolute;
    width: 100%;
    z-index: 2;
    transform: translateY(10vw);

    ${breakup.xlarge` transform: translateY(20vh); `}
`;

const sharedTitle = (props) => css`
    padding-left: 1.2rem;
    color: ${props.theme.bc1};

    transition: clip-path 1.4s ${props => props.theme.bezzy};
    transition-delay: .3s;
    clip-path: inset(${(props) => props.isVisible ? `0% 0% 0% 0%` : `50% 0% 50% 0%`});

    ${breakup.medium`
        padding-left: 4.2rem;
    `}
`;

export const Title = styled(H1)(props => css`
    ${sharedTitle}
`);

export const TitleH2 = styled(H2)(props => css`
    ${sharedTitle}
`);

export const MarqueeJacket = styled.div(props => css`
    position: relative;
    z-index: 1;

    transition: all 1.4s ${props.theme.bezzy};
    transform: translateX(${props.isVisible ? `0` : `50vw`});
`);