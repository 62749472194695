// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, H5 } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)((props) => css`
    position: relative;
    margin-bottom: 3.6rem;

    transition: all .6s ${props.theme.bezzy};
    padding-bottom: ${props.isActive ? `2.4rem` : `0rem`};

    &:before {
        transition: all .6s ${props.theme.bezzy};

        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: ${props.theme.bc2};
        opacity: ${props.isActive ? 1 : 0.36};
    }
`);

export const Title = styled(H5)((props) => css`
    transition: all .6s ${props.theme.bezzy};
    text-transform: none;

    display: flex;
    align-items: top;
    justify-content: space-between;
    padding-bottom: 2.4rem;

    color: ${props.isActive ? props.theme.bc1 : props.theme.white};

    span {
        transition: all .6s ${props.theme.bezzy};

        margin-left: 3.6rem;
        
        display: flex;
        justify-content: center;
        align-items: center;

        border: 1px dashed ${props.theme.bc2};
        border-radius: 100%;
        background: ${props.isActive ? props.theme.bc2 : `transparent`};
        
        padding: 1.2rem;
        width: 4.4rem;
        height: 4.4rem;

        svg {
            transition: all .6s ${props.theme.bezzy};
            fill: ${props.theme.white};

            transform: rotate(${props.isActive ? `180deg` : `0deg`});
        }
    }

    &:hover {
        color: ${props.isActive ? props.theme.bc1 : props.theme.bc2};

        span {
            background: ${props.theme.bc2};
        }
    }
`);


export const Content = styled.div((props) => css`
    transition: max-height 1s cubic-bezier(0, 1, 0, 1);
    transition-delay: 0s;

    display: flex;
    flex-direction: column;
    overflow: hidden;

    max-height: 0px;

    ${breakup.large` padding-right: calc(8.333vw * 1); `}

    ${props.isActive && css`
        max-height: 1000rem;
        transition: max-height 2s ease-in-out;
        transition-delay: 0s;
    `}

    p, li, ol {
        color: ${props.theme.bc6};
    }
`);