// Imports
// ------------
import React from 'react';
import Parser from '@parts/Parser';
import ExperienceCard from './Card';
import { Row, Column } from '@waffl';

// Styles
// ------------
import { Jacket, Title, List } from './styles';

// Component
// ------------
const ExperiencesList = ({ data }) => {
    return (
        <Jacket padBottom>
            <Row isExpanded>
                <Column small={12} medium={6} mpad>
                    <Title>Choose your experience</Title>
                </Column>
            </Row>
            <Row isExpanded>
                <Column small={12} medium={4} pushMedium={1} mpad>
                    <Parser content="<p>Experience days are by inquiry only for now. The dates listed here are all that's available, so be quick to make a booking!</p>" />
                </Column>
            </Row>
            <Row isExpanded>
                <Column small={12} medium={10} pushMedium={1} mpad>
                    <List padTop>
                        {data.map(( exp, i ) => (
                            <ExperienceCard key={i} data={exp} />
                        ))}
                    </List>
                </Column>
            </Row>
        </Jacket>
    );
}

export default ExperiencesList;