// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Div, H3, P, Span, Em } from '@tackl';
import { pStyles, spanStyles } from '@tackl-type';

// Exports
// ------------
export const Jacket = styled(Div)((props) => css`
    position: relative;
    margin-bottom: 3.6rem;

    background: ${props.theme.bc3};
    box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.6);

    ${breakup.medium` margin-bottom: 6rem; `}
`);

export const ImageJacket = styled.div`
    position: relative;
    height: 100%;

    .gatsby-image-wrapper { height: 100%; }
`;

export const Review = styled.div((props) => css`
    background: ${props.theme.black};
    padding: 2.4rem;

    display: flex;
    flex-direction: row;

    ${breakup.medium`
        position: absolute;
        bottom: 2.4rem; left: -2.4rem;
        z-index: 2;
        width: 80%;
        
        background: ${props.theme.bc3};
    `}

    ${breakup.large` left: -3.6rem; bottom: 3.6rem; `}

    svg {
        min-width: 2.4rem;
        width: 2.4rem;
        height: 2.4rem;
        fill: ${props.theme.bc2};
    }
`);

export const ReviewDetail = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 2.4rem;
`;

export const Rating = styled.div((props) => css`
    svg {
        fill: ${props.theme.bc1};
    }
`);

export const ReviewContent = styled(P)((props) => css`
    padding: .6rem 0;

    font-style: italic;
    font-size: 1.4rem;
    color: ${props.theme.white};
`);

export const ReviewAuthor = styled(Em)`
    font-size: 1.3rem;
    opacity: .6;
`;


export const Content = styled.div`
    position: relative;
    height: 100%;
    padding: 3.6rem 2.4rem;
`;

export const Title = styled(H3)((props) => css`
    margin-bottom: 2.4rem;
`);

export const Description = styled.div`
    opacity: .6;
    margin-bottom: 2.4rem;
`;


export const Selector = styled.div`
    position: relative;
`;

export const Selected = styled.div((props) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 1.2rem;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;

        height: 1px;
        width: 100%;
        background: ${props.theme.white};
        opacity: .12;
    }

    em {
        ${spanStyles}
        text-transform: uppercase;
        font-family: ${props.theme.heading};
        font-weight: 500;
        color: ${props.theme.bc1};
    }

    span {
        &:first-child {
            ${pStyles}
            font-size: 1.6rem;
            color: ${props.theme.white};
            opacity: .6;
        }

        &:last-child {
            z-index: 12;

            ${spanStyles}
            text-transform: uppercase;
            font-family: ${props.theme.heading};
            font-weight: 500;
            color: ${props.theme.bc2};
        }
    }
`);

export const SelectorTrigger = styled.div((props) => css`
    position: absolute;
    top: 0;
    z-index: 2;

    width: 100%;
    height: 100%;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;

        transition: all .6s ${props.theme.bezzy};
        height: 1px;
        width: 0%;
        background: ${props.theme.bc2};
        opacity: 1;
    }

    &:hover {
        &:before {
            width: 100%;
        }
    }

    ${props.isActive && css`
        &:before {
            width: 100%;
        }
    `}
`);

export const SelectorList = styled.div((props) => css`
    position: absolute;
    top: calc(3.8rem + 0.8rem);
    left: 0;
    width: 100%;
    border: 1px solid ${props.theme.bc2};
    border-radius: 0.6rem;
    background-color: ${props.theme.bc3};
    box-shadow: 0 0 12px ${props.theme.black};
    z-index: 1;
    display: ${props.isActive ? `block` : `none`};
`);

export const Option = styled.div((props) => css`
    position: relative;
    display: flex;
    width: 100%;
    padding: 1.2rem 2.4rem;
    overflow: hidden;

    ${spanStyles}

    &:first-child {
        border-top-left-radius: 0.6rem;
    }

    &:last-child {
        border-bottom-left-radius: 0.6rem;
    }

    &:not(:last-child)::after {
        content: '';
        position: absolute;
        bottom: 0; left: 0; right: 0;

        width: 100%;
        border-bottom: 1px solid ${props.theme.bc2};
        opacity: .24;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0; left: 0; bottom: 0;
        width: 0%;
        height: 100%;

        transition: all .6s ${props.theme.bezzy};
        background: ${props.theme.bc2};
        opacity: .12;
    }

    &:hover {
        &:before {
            width: 100%;
        }
    }

    ${props.isActive && css`
        &:before {
            width: 1.2rem;
            opacity: 1;
            background: ${props.theme.bc1};
        }

        &:hover {
            pointer-events: none;
        }
    `}
`);

export const Nodates = styled(Span)`
    display: flex;
    flex-flow: column;
    align-items: center;

    ${breakup.medium` align-items: flex-start; `}
`;


export const End = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;

    margin-top: 2.4rem;

    ${breakup.medium` flex-flow: row; `}
`;

export const Price = styled(Span)((props) => css`
    margin-bottom: 2.4rem;

    ${breakup.medium` margin-bottom: 0; `}

    span {
        ${spanStyles}
        text-transform: uppercase;
        font-family: ${props.theme.heading};
        font-weight: 500;
        color: ${props.theme.bc2};
    }
`);

export const ButtonJacket = styled.div``;