// Imports
// ------------
import React from 'react';
import MarqueeHeadline from '@parts/MarqueeHeadline';
import Icon from '@icons';
import { loader, pagetrans } from '@states';
import { Row, Column } from '@waffl';
import { observer } from 'mobx-react-lite';

// Styles
// ------------
import { Jacket, Back, TitleJacket, Title, TitleH2, MarqueeJacket } from './styles';

// Component
// ------------
const HeroAll = ({ noPad, hasBackBtn, onClick, isDark, title, sub, goLarge, h2 }) => {
    return (
        <Jacket padTop={!noPad}>
            <TitleJacket>
                <Row isExpanded>
                    <Column small={12} medium={goLarge ? 7 : 6}>
                        {hasBackBtn && <Back onClick={onClick} className="ch"><Icon type="left" /><span>Go back</span></Back>}

                        {h2 ?
                            <TitleH2 isVisible={loader.isComplete && pagetrans.isDefault}>{title}</TitleH2>
                        :
                            <Title isVisible={loader.isComplete && pagetrans.isDefault}>{title}</Title>
                        }
                    </Column>
                </Row>
            </TitleJacket>

            <MarqueeJacket isVisible={loader.isComplete && pagetrans.isDefault}>
                <MarqueeHeadline isDark={isDark} data={sub} />
            </MarqueeJacket>
        </Jacket>
    );
}

export default observer(HeroAll);