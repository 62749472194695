// Imports
// ------------
import React, { useRef, useState, useEffect } from 'react';
import Input from '@parts/Input';
import Button from '@parts/Button';
import axios from 'axios';
import { Row, Column } from '@waffl';
import { experienceModal } from '@states';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

// Styles
// ------------
import { Jacket, ButtonJacket, Response, Content } from './styles';

// Component
// ------------
const ExpModalForm = ({ formSuccessMessage, formErrorMessage }) => {
    // NOTE • MIXPANEL
	const mixpanel = useMixpanel();

    // NOTE • Refs
    // ------
    const nameField = useRef(null);
    const emailField = useRef(null);
    const expTitle = useRef(null);
    const expDate = useRef(null);
    const expPrice = useRef(null);

    // NOTE • States
    // ------
    const [formData, setFormData] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailTest, setEmailTest] = useState('');
	const [responseGood, setResponseGood] = useState(false);
    const [responseError, setResponseError] = useState(false);

    // NOTE • Regex
    // ------
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

    // NOTE • Handle Input Change
    // ------
    const handleChange = (e) => {
        if (e.target.type === "email") {
            setEmailTest({ value: e.target.value });
            const test = validEmailRegex.test(emailTest.value);
            setEmailError(!test);
        }

        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleBlur = (e) => {
        if (e.target.type === "email") {
            setEmailTest({ value: e.target.value.trim().toLowerCase() });
            const test = validEmailRegex.test(emailTest.value);
            setEmailError(!test);
        }

        if(!emailError || e.target.value.length == 0) {
            setEmailError(false);
        }
    }

    // NOTE • Periodically correct the email validation
    // ------
    useEffect(() => {
        setTimeout(() => {
            if (emailField.current && emailField.current.matches(':-webkit-autofill')) {
                setEmailTest({ value: emailField.current.value.trim().toLowerCase() });
                const test = validEmailRegex.test(emailTest.value);
                setEmailError(!test);
            }
        }, 1000);
    });

    useEffect(() => {
        if(expTitle.current?.value) {
            setFormData({
                ...formData,
                [expTitle.current.name] : expTitle.current?.value,
                [expDate.current.name] : expDate.current?.value,
                [expPrice.current.name] : expPrice.current?.value,
            });
        }
	}, [expTitle.current?.value, expDate.current?.value, expPrice.current?.value]);

    // NOTE • Handle Form Submission
    // ------
    const handleSubmit = (e) => {
        e.preventDefault();

        function encode(data) {
			return Object.keys(data)
				.map(
					(key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
				)
				.join('&');
		}

        const axiosOptions = {
			url: window.location.href,
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			data: encode({ 'form-name': e.target.getAttribute('name'), ...formData }),
		};

        // console.dir(formData)

		axios(axiosOptions)
			.then((response) => {
                setResponseGood(true);

                mixpanel.track(`Submission for (${experienceModal.selectedExpTitle})`)
    
                setTimeout(() => {
                    setFormData('');
                    setEmailError(false);
                    setEmailTest('');
                }, 3000);
    
                setTimeout(() => {
                    setResponseGood(false);
                }, 4000);
			})
			.catch((err) => {
                setResponseError(true);
    
                setTimeout(() => {
                    setFormData('');
                    setEmailError(false);
                    setEmailTest('');
                }, 3000);
    
                setTimeout(() => {
                    setResponseError(false);
                }, 4000);
            });
    }

    return (
        <>
            {responseGood || responseError ? (
                <Response good={responseGood} bad={responseError}>
                    <Content>
                        {responseGood ? formSuccessMessage : formErrorMessage}
                    </Content>
                </Response>
            ) : (
                <Jacket
                    onSubmit={handleSubmit}
                    name='Experience Day Request'
                    method='POST'
                    data-netlify='true'
                    netlify-honeypot='bot-field'
                >
                    <input type='hidden' name='bot-field' />
                    <input type='hidden' name='form-name' value='Experience Day Request' />
                    <input ref={expTitle} type='hidden' name='Experience Title' value={experienceModal.selectedExpTitle} />
                    <input ref={expDate} type='hidden' name='Experience Date' value={experienceModal.selectedExpDate} />
                    <input ref={expPrice} type='hidden' name='Experience Price' value={experienceModal.selectedExpPrice} />

                    <Row isExpanded>
                        <Column small={12} large={6}>
                            <Input
                                ref={nameField}
                                isRequired
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label='Name*'
                                type='text'
                                name='name'
                                value={formData.name ? formData.name : ''}
                            />
                        </Column>
                        <Column small={12} large={6}>
                            <Input
                                ref={emailField}
                                isRequired
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label='Email*'
                                type='email'
                                name='email'
                                emailError={emailError}
                                value={formData.email ? formData.email : ''}
                            />
                        </Column>
                    </Row>

                    <Row isExpanded>
                        <Column small={12}>
                            <Input
                                isBig
                                isRequired
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label='Your message*'
                                type='textarea'
                                name='message'
                                value={formData.message ? formData.message : ''}
                            />
                        </Column>
                    </Row>

                    <Row isExpanded>
                        <Column small={12}>
                            <ButtonJacket>
                                <Button
                                    isBtn
                                    isNext
                                    label='Send'
                                    disabled={
                                        !formData.name ||
                                        !formData.email ||
                                        !formData.message
                                    }
                                />
                            </ButtonJacket>
                        </Column>
                    </Row>
                </Jacket>
            )}
        </>
    );
}

export default ExpModalForm;