// Imports
// ------------
import React from 'react';
import Hero from '@parts/Hero';
import ExpModalForm from './Form';
import { Row, Column } from '@waffl';
import { experienceModal } from '@states';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';

// Styles
// ------------
import { Jacket, Content, FormJacket, Details, Label, Item } from './styles';

// Component
// ------------
const ExpContactModal = ({ formSuccessMessage, formErrorMessage }) => {
    // NOTE • Handle modal
    const handleModal = action(() => {
        experienceModal.isActive = !experienceModal.isActive;
    });

    return (
        <Jacket isActive={experienceModal.isActive}>
            <Content padBottom>
                <Hero title="Book your experience day" sub="Experiences 2022" hasBackBtn onClick={handleModal} isDark goLarge />

                <FormJacket padTop>
                    <Row isExpanded>
                        <Column small={12} medium={3} pushMedium={1} mpad>
                            <Details>
                                <Label>Your selection:</Label>
                                <Item big>{experienceModal.selectedExpTitle}</Item>
                                <Item>When: {experienceModal.selectedExpDate ? experienceModal.selectedExpDate : `Next year`}</Item>
                                <Item price>Price: &pound;{experienceModal.selectedExpPrice}</Item>
                            </Details>
                        </Column>
                        <Column small={12} medium={7} pushMedium={1}>
                            <ExpModalForm formSuccessMessage={formSuccessMessage} formErrorMessage={formErrorMessage} />
                        </Column>
                    </Row>
                </FormJacket>
            </Content>
        </Jacket>
    );
}

export default observer(ExpContactModal);