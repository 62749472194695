// Imports
// ------------
import React from 'react';
import Hero from '@parts/Hero';
import Parser from '@parts/Parser';
import Button from '@parts/Button';
import Question from '@parts/Experiences/Faqs/Question';
import { Row, Column } from '@waffl';

// Styles
// ------------
import { Jacket, HeroJacket, ButtonJacket, Content, Questions } from './styles';

// Component
// ------------
const Faqs = ({ intro, data }) => {
    return (
        <Jacket id="faqs" padBottom>
            <HeroJacket>
                <Hero noPad h2 title="Frequently Asked Questions" sub="Frequently Asked" />
            </HeroJacket>

            <Content padTop>
                <Row isExpanded>
                    <Column small={12} medium={4} pushMedium={1} large={3} mpad>
                        <Parser content={intro} />
                        <ButtonJacket>
                            <Button isLink isNext label="Ask a question" to="/contact/" />
                        </ButtonJacket>
                    </Column>
                    <Column small={12} medium={6} pushMedium={2} mpad>
                        <Questions>
                            {data.map((q, i) => (
                                <Question key={i} question={q.question} content={q.answerNode.childMarkdownRemark.html} />
                            ))}
                        </Questions>
                    </Column>
                </Row>
            </Content>
        </Jacket>
    );
}

export default Faqs;