// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, P } from '@tackl';

// Exports
// ------------
export const Jacket = styled.form``;

export const ButtonJacket = styled.div``;

export const Response = styled.div((props) => css`
    position: relative;
    display: flex;

    padding: 2.4rem;
    border: 2px dashed ${props.good ? props.theme.pos : props.theme.neg};

    ${breakup.medium` padding: 3.6rem; `}
`);

export const Content = styled(P)`
    color: ${props => props.theme.white};
`;