// Imports
// ------------
import React, { useRef, useEffect } from 'react';
import Parser from '@parts/Parser';
import Button from '@parts/Button';
import gsap from 'gsap';
import Scrollbar from 'smooth-scrollbar';
import { Row, Column } from '@waffl';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Styles
// ------------
import { Jacket, Content, Label, ImageList, Image, Topographic } from './styles';

// Component
// ------------
gsap.registerPlugin(ScrollTrigger);

const ExperiencesOverview = ({ content, images }) => {
    // NOTE • Refs
    const trigger = useRef();
	const target1 = useRef();
	const target2 = useRef();
	const target3 = useRef();
	const target4 = useRef();
	const target5 = useRef();

    // NOTE • Onload - Animate Scroll
    useEffect(() => {
        // NOTE • Variables for scroller
        const scroller = document.querySelector('#scroller');
        const bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

        // NOTE • Proxy for scroller to pick up smooth scroll
        ScrollTrigger.scrollerProxy(scroller, {
            scrollTop(value) {
                if (arguments.length) {
                    bodyScrollBar.scrollTop = value;
                }
                return bodyScrollBar.scrollTop;
            }
        });

        // NOTE • Actual Transition
        gsap.to(target1.current, {
            x: `10%`,
            y: `-15%`,
            ease: "none",
            scrollTrigger: {
                scroller: scroller,
                trigger: trigger.current,
                start: "30% 100%",
                end: "30% 50%",
                scrub: 0.5,
                markers: false,
            },
        });
        
        gsap.to(target2.current, {
            x: `0%`,
            y: `0%`,
            ease: "none",
            scrollTrigger: {
                scroller: scroller,
                trigger: trigger.current,
                start: "40% 100%",
                end: "30% 50%",
                scrub: 0.5,
                markers: false,
            },
        });
        
        gsap.to(target3.current, {
            x: `0%`,
            y: `20%`,
            ease: "none",
            scrollTrigger: {
                scroller: scroller,
                trigger: trigger.current,
                start: "40% 100%",
                end: "30% 50%",
                scrub: 0.5,
                markers: false,
            },
        });
        
        gsap.to(target4.current, {
            x: `0%`,
            y: `50%`,
            ease: "none",
            scrollTrigger: {
                scroller: scroller,
                trigger: trigger.current,
                start: "40% 100%",
                end: "30% 50%",
                scrub: 0.5,
                markers: false,
            },
        });
        
        gsap.to(target5.current, {
            width: `70%`,
            ease: "none",
            scrollTrigger: {
                scroller: scroller,
                trigger: trigger.current,
                start: "40% 100%",
                end: "30% 50%",
                scrub: 0.05,
                markers: false,
            },
        });

        // NOTE • Move markers to correct position
        if (document.querySelector(".gsap-marker-scroller-start")) {
            const markers = gsap.utils.toArray('[class *= "gsap-marker"]');

            bodyScrollBar.addListener(({ offset }) => {
                gsap.set(markers, { marginTop: -offset.y });
            });
        }

        // NOTE • Update positions & timing
        bodyScrollBar.addListener(ScrollTrigger.update);
    }, []);

    return (
        <Jacket pad ref={trigger}>
            <Row isExpanded isCollapsed isCenter>
                <Column small={12} large={6}>
                    
                    <Row isExpanded>
                        <Column medium={8} pushMedium={2} mpad>
                            <Content>
                                <Label>Overview</Label>
                                <Parser content={content} />
                                <Button isHash href="#faqs" label="See FAQ's" isNext />
                            </Content>
                        </Column>
                    </Row>

                </Column>
                <Column small={12} large={6}>
                    <ImageList>
                        <Image ref={target1} imageOne>
                            <GatsbyImage image={images[0].data} alt={images[0].alt} />
                        </Image>
                        <Image ref={target2} imageTwo>
                            <GatsbyImage image={images[1].data} alt={images[1].alt} />
                        </Image>
                        <Image ref={target3} imageThree>
                            <GatsbyImage image={images[2].data} alt={images[2].alt} />
                        </Image>
                        <Image ref={target4} imageFour>
                            <GatsbyImage image={images[3].data} alt={images[3].alt} />
                        </Image>

                        <Topographic ref={target5} />
                    </ImageList>
                </Column>
            </Row>
        </Jacket>
    );
}

export default ExperiencesOverview;